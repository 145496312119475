.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 50;
}

.modal-main {
    position: fixed;
    background: white;
    width: 90%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1rem;
    border-radius: $borderRadius;
    max-height: 90%;
    overflow: scroll;
}


.modal-fadein-enter {
    opacity: 0;
}
.modal-fadein-enter-active {
  opacity: 1;
  transition: opacity 300ms, transform 300ms;
}
.modal-fadein-exit {
  opacity: 0;
}
.modal-fadein-exit-active {
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}
