$icomoon-font-family: "paiazo" !default;
$icomoon-font-path: "./fonts" !default;

$icon-cloche: "\e900";
$icon-kiwi: "\f13a";
$icon-taco: "\f13f";
$icon-olive: "\f142";
$icon-pepper: "\f149";
$icon-pumpkin: "\f116";
$icon-chili: "\f133";
$icon-sushi2: "\f135";
$icon-artichoke: "\f103";
$icon-lattuce: "\f14f";
$icon-toaster: "\f15c";
$icon-ramen: "\f140";
$icon-zenzi: "\f10d";
$icon-sandwich: "\f10e";
$icon-onion: "\f112";
$icon-mustards: "\f139";
$icon-orange: "\f147";
$icon-lollipop: "\f150";
$icon-onionrings: "\f145";
$icon-fries: "\f12e";
$icon-bretzel: "\f115";
$icon-roast-chicken: "\f14e";
$icon-bread: "\f155";
$icon-pizza: "\f114";
$icon-shrimps: "\f119";
$icon-cupcake: "\f10b";
$icon-oil: "\f161";
$icon-burrito: "\f13e";
$icon-skewer: "\f154";
$icon-ananas: "\f113";
$icon-spaghetti: "\f158";
$icon-watermelon: "\f156";
$icon-salami: "\f151";
$icon-lobster: "\f13c";
$icon-biscuit: "\f106";
$icon-doner: "\f129";
$icon-panino: "\f118";
$icon-tomato: "\f11b";
$icon-salt: "\f152";
$icon-croissant: "\f127";
$icon-eggplant: "\f10c";
$icon-egg: "\f11d";
$icon-yogurt: "\f163";
$icon-donut: "\f12a";
$icon-cheese: "\f15b";
$icon-oreo: "\f160";
$icon-honey: "\f136";
$icon-bacon: "\f104";
$icon-sardines: "\f153";
$icon-mussell: "\f111";
$icon-pear: "\f148";
$icon-cherry: "\f15f";
$icon-cinnamon-roll: "\f124";
$icon-grapes: "\f11f";
$icon-peas: "\f146";
$icon-hamburger: "\f109";
$icon-crab: "\f10a";
$icon-candy: "\f162";
$icon-cheeseburger: "\f144";
$icon-mushroom: "\f110";
$icon-jelly: "\f138";
$icon-chocolate: "\f122";
$icon-sushi: "\f15a";
$icon-steak: "\f131";
$icon-baguette: "\f143";
$icon-strawberry: "\f11a";
$icon-peach: "\f102";
$icon-sundae: "\f12f";
$icon-broccoli: "\f107";
$icon-popcorn: "\f14b";
$icon-chopstick: "\f12d";
$icon-jam: "\f137";
$icon-fried-egg: "\f130";
$icon-avocado: "\f132";
$icon-corn: "\f125";
$icon-ice-cream-cup: "\f159";
$icon-salad: "\f13d";
$icon-fish: "\f12b";
$icon-tortillas: "\f128";
$icon-banana: "\f15e";
$icon-apple: "\f101";
$icon-pancake: "\f12c";
$icon-opened-can: "\f120";
$icon-pepperoni: "\f105";
$icon-cake: "\f11c";
$icon-chicken: "\f121";
$icon-apple-pie: "\f100";
$icon-pomegranade: "\f14a";
$icon-rice: "\f11e";
$icon-soup: "\f134";
$icon-lemon: "\f13b";
$icon-carrot: "\f108";
$icon-water: "\f157";
$icon-muffin: "\f14d";
$icon-can: "\f15d";
$icon-wine-glass: "\f164";
$icon-cork: "\f165";
$icon-lemonade: "\f166";
$icon-bottle-water: "\f167";
$icon-ice-cream: "\f168";
$icon-drink: "\f169";
$icon-beer: "\f16a";
$icon-coffee: "\f16b";
$icon-rhum: "\f16c";
$icon-draft-beer: "\f16d";


@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?9wo07r');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?9wo07r#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?9wo07r') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?9wo07r') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?9wo07r##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cloche {
  &:before {
    content: $icon-cloche; 
  }
}
.icon-kiwi {
  &:before {
    content: $icon-kiwi; 
  }
}
.icon-taco {
  &:before {
    content: $icon-taco; 
  }
}
.icon-olive {
  &:before {
    content: $icon-olive; 
  }
}
.icon-pepper {
  &:before {
    content: $icon-pepper; 
  }
}
.icon-pumpkin {
  &:before {
    content: $icon-pumpkin; 
  }
}
.icon-chili {
  &:before {
    content: $icon-chili; 
  }
}
.icon-sushi2 {
  &:before {
    content: $icon-sushi2; 
  }
}
.icon-artichoke {
  &:before {
    content: $icon-artichoke; 
  }
}
.icon-lattuce {
  &:before {
    content: $icon-lattuce; 
  }
}
.icon-toaster {
  &:before {
    content: $icon-toaster; 
  }
}
.icon-ramen {
  &:before {
    content: $icon-ramen; 
  }
}
.icon-zenzi {
  &:before {
    content: $icon-zenzi; 
  }
}
.icon-sandwich {
  &:before {
    content: $icon-sandwich; 
  }
}
.icon-onion {
  &:before {
    content: $icon-onion; 
  }
}
.icon-mustards {
  &:before {
    content: $icon-mustards; 
  }
}
.icon-orange {
  &:before {
    content: $icon-orange; 
  }
}
.icon-lollipop {
  &:before {
    content: $icon-lollipop; 
  }
}
.icon-onionrings {
  &:before {
    content: $icon-onionrings; 
  }
}
.icon-fries {
  &:before {
    content: $icon-fries; 
  }
}
.icon-bretzel {
  &:before {
    content: $icon-bretzel; 
  }
}
.icon-roast-chicken {
  &:before {
    content: $icon-roast-chicken; 
  }
}
.icon-bread {
  &:before {
    content: $icon-bread; 
  }
}
.icon-pizza {
  &:before {
    content: $icon-pizza; 
  }
}
.icon-shrimps {
  &:before {
    content: $icon-shrimps; 
  }
}
.icon-cupcake {
  &:before {
    content: $icon-cupcake; 
  }
}
.icon-oil {
  &:before {
    content: $icon-oil; 
  }
}
.icon-burrito {
  &:before {
    content: $icon-burrito; 
  }
}
.icon-skewer {
  &:before {
    content: $icon-skewer; 
  }
}
.icon-ananas {
  &:before {
    content: $icon-ananas; 
  }
}
.icon-spaghetti {
  &:before {
    content: $icon-spaghetti; 
  }
}
.icon-watermelon {
  &:before {
    content: $icon-watermelon; 
  }
}
.icon-salami {
  &:before {
    content: $icon-salami; 
  }
}
.icon-lobster {
  &:before {
    content: $icon-lobster; 
  }
}
.icon-biscuit {
  &:before {
    content: $icon-biscuit; 
  }
}
.icon-doner {
  &:before {
    content: $icon-doner; 
  }
}
.icon-panino {
  &:before {
    content: $icon-panino; 
  }
}
.icon-tomato {
  &:before {
    content: $icon-tomato; 
  }
}
.icon-salt {
  &:before {
    content: $icon-salt; 
  }
}
.icon-croissant {
  &:before {
    content: $icon-croissant; 
  }
}
.icon-eggplant {
  &:before {
    content: $icon-eggplant; 
  }
}
.icon-egg {
  &:before {
    content: $icon-egg; 
  }
}
.icon-yogurt {
  &:before {
    content: $icon-yogurt; 
  }
}
.icon-donut {
  &:before {
    content: $icon-donut; 
  }
}
.icon-cheese {
  &:before {
    content: $icon-cheese; 
  }
}
.icon-oreo {
  &:before {
    content: $icon-oreo; 
  }
}
.icon-honey {
  &:before {
    content: $icon-honey; 
  }
}
.icon-bacon {
  &:before {
    content: $icon-bacon; 
  }
}
.icon-sardines {
  &:before {
    content: $icon-sardines; 
  }
}
.icon-mussell {
  &:before {
    content: $icon-mussell; 
  }
}
.icon-pear {
  &:before {
    content: $icon-pear; 
  }
}
.icon-cherry {
  &:before {
    content: $icon-cherry; 
  }
}
.icon-cinnamon-roll {
  &:before {
    content: $icon-cinnamon-roll; 
  }
}
.icon-grapes {
  &:before {
    content: $icon-grapes; 
  }
}
.icon-peas {
  &:before {
    content: $icon-peas; 
  }
}
.icon-hamburger {
  &:before {
    content: $icon-hamburger; 
  }
}
.icon-crab {
  &:before {
    content: $icon-crab; 
  }
}
.icon-candy {
  &:before {
    content: $icon-candy; 
  }
}
.icon-cheeseburger {
  &:before {
    content: $icon-cheeseburger; 
  }
}
.icon-mushroom {
  &:before {
    content: $icon-mushroom; 
  }
}
.icon-jelly {
  &:before {
    content: $icon-jelly; 
  }
}
.icon-chocolate {
  &:before {
    content: $icon-chocolate; 
  }
}
.icon-sushi {
  &:before {
    content: $icon-sushi; 
  }
}
.icon-steak {
  &:before {
    content: $icon-steak; 
  }
}
.icon-baguette {
  &:before {
    content: $icon-baguette; 
  }
}
.icon-strawberry {
  &:before {
    content: $icon-strawberry; 
  }
}
.icon-peach {
  &:before {
    content: $icon-peach; 
  }
}
.icon-sundae {
  &:before {
    content: $icon-sundae; 
  }
}
.icon-broccoli {
  &:before {
    content: $icon-broccoli; 
  }
}
.icon-popcorn {
  &:before {
    content: $icon-popcorn; 
  }
}
.icon-chopstick {
  &:before {
    content: $icon-chopstick; 
  }
}
.icon-jam {
  &:before {
    content: $icon-jam; 
  }
}
.icon-fried-egg {
  &:before {
    content: $icon-fried-egg; 
  }
}
.icon-avocado {
  &:before {
    content: $icon-avocado; 
  }
}
.icon-corn {
  &:before {
    content: $icon-corn; 
  }
}
.icon-ice-cream-cup {
  &:before {
    content: $icon-ice-cream-cup; 
  }
}
.icon-salad {
  &:before {
    content: $icon-salad; 
  }
}
.icon-fish {
  &:before {
    content: $icon-fish; 
  }
}
.icon-tortillas {
  &:before {
    content: $icon-tortillas; 
  }
}
.icon-banana {
  &:before {
    content: $icon-banana; 
  }
}
.icon-apple {
  &:before {
    content: $icon-apple; 
  }
}
.icon-pancake {
  &:before {
    content: $icon-pancake; 
  }
}
.icon-opened-can {
  &:before {
    content: $icon-opened-can; 
  }
}
.icon-pepperoni {
  &:before {
    content: $icon-pepperoni; 
  }
}
.icon-cake {
  &:before {
    content: $icon-cake; 
  }
}
.icon-chicken {
  &:before {
    content: $icon-chicken; 
  }
}
.icon-apple-pie {
  &:before {
    content: $icon-apple-pie; 
  }
}
.icon-pomegranade {
  &:before {
    content: $icon-pomegranade; 
  }
}
.icon-rice {
  &:before {
    content: $icon-rice; 
  }
}
.icon-soup {
  &:before {
    content: $icon-soup; 
  }
}
.icon-lemon {
  &:before {
    content: $icon-lemon; 
  }
}
.icon-carrot {
  &:before {
    content: $icon-carrot; 
  }
}
.icon-water {
  &:before {
    content: $icon-water; 
  }
}
.icon-muffin {
  &:before {
    content: $icon-muffin; 
  }
}
.icon-can {
  &:before {
    content: $icon-can; 
  }
}
.icon-wine-glass {
  &:before {
    content: $icon-wine-glass; 
  }
}
.icon-cork {
  &:before {
    content: $icon-cork; 
  }
}
.icon-lemonade {
  &:before {
    content: $icon-lemonade; 
  }
}
.icon-bottle-water {
  &:before {
    content: $icon-bottle-water; 
  }
}
.icon-ice-cream {
  &:before {
    content: $icon-ice-cream; 
  }
}
.icon-drink {
  &:before {
    content: $icon-drink; 
  }
}
.icon-beer {
  &:before {
    content: $icon-beer; 
  }
}
.icon-coffee {
  &:before {
    content: $icon-coffee; 
  }
}
.icon-rhum {
  &:before {
    content: $icon-rhum; 
  }
}
.icon-draft-beer {
  &:before {
    content: $icon-draft-beer; 
  }
}



 @font-face {
  font-family: 'fontello';
  src: url('#{$icomoon-font-path}/fontello.eot?99364413');
  src: url('#{$icomoon-font-path}/fontello.eot?99364413#iefix') format('embedded-opentype'),
       url('#{$icomoon-font-path}/fontello.woff2?99364413') format('woff2'),
       url('#{$icomoon-font-path}/fontello.woff?99364413') format('woff'),
       url('#{$icomoon-font-path}/fontello.ttf?99364413') format('truetype'),
       url('#{$icomoon-font-path}/fontello.svg?99364413#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?99364413#fontello') format('svg');
  }
}
*/
 
[class^="fe-icon-"]:before, [class*=" fe-icon-"]:before  {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.fe-icon-ok:before { content: '\2713'; } /* '✓' */
.fe-icon-takeaway:before { content: '\e02a'; } /* '' */
.fe-icon-cancel:before { content: '\e109'; } /* '' */
.fe-icon-paypal:before { content: '\f1ed'; } /* '' */
.fe-icon-credit-card-alt:before { content: '\f283'; } /* '' */
.fe-icon-dollar:before { content: '💵'; } /* '\1f4b5' */
.fe-icon-delivery:before { content: '🚚'; } /* '\1f69a' */