
/* COLOR */
$darkGray: #87838C;
$textColor: #464646;
$paiazoColor: #5a2434;



/* SIZE */

$borderRadius: 1rem;
$cartClosedHeight: 4.2rem;

