@import '_variables';

.expanded-product{
	position: fixed;
	z-index: 6;
	background-color: white;
	.card{
		width: 100%;
		height: 100%;
		box-shadow: none;
		.card__image{
			.close-icon{
				width: 1rem;
				height: 1rem;
				float: right;
				margin: 1rem;
				opacity: .8;
				filter: invert(1) drop-shadow( 1px 1px 2px rgba(0, 0, 0, .7));
				&:hover{
					opacity: 1;
				}
			}
		}
		.card__action-bar{
			padding-top: 1rem
		}
		.card__text{
			box-shadow: none;
			display: flex;
			flex-direction: column;
			.card__title{
				white-space: normal;
				text-overflow: unset;
				overflow: unset;

			}
			.card__long-description{
				overflow-y: scroll;
			}
		}
		&.nowrap .card__title{
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}

}

.card {
	position: relative;
	border-radius: $borderRadius;
	background-color: #fff;
	width: 20rem;
	transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
	box-shadow: 0 0px 15px 2px rgba(0, 0, 0, 0.2);
	display: flex;
	flex-direction: column;

	&:hover {
		cursor: pointer;
	}


	.allergens{

		display: flex;
		justify-self: self-end;
		justify-content: flex-end;
		flex-direction: column;
		height: 100%;
		p{
			margin-bottom: 0;
		}
		.allergens-wrapper{
			display: flex;
			img{
				width: 2rem;
			}
		}
	}

}
.card__image {
	background-size: cover;
	background-position: center bottom;
	background-repeat: no-repeat;
	height: 14rem;
	overflow: hidden;
	display: block;
	border-radius: $borderRadius $borderRadius 0 0;
	opacity: 1;
	transition: all 200ms cubic-bezier(0.075, 0.82, 0.165, 1);
	position: relative;
	background-color: #f2f2f2;

}
.card__title {
	user-select: none;
	font-size: 1.5rem;
	color: var(--color-title);
	margin: 0;
	font-weight: 700;
	line-height: 1.3;
	transition: all 200ms cubic-bezier(0.075, 0.82, 0.165, 1);
	margin-bottom: .5rem;
}
.card__subtitle {
	user-select: none;
	font-size: .8rem;
	line-height: 1.2;
	display: block;
	color: #616161;
	margin-top:.5rem;
	transition: all 200ms cubic-bezier(0.075, 0.82, 0.165, 1);
	span{
		color: #929292;
	}
}
.card__text {
	font-size: 1rem;
	display: flex;
	flex-direction: column;
	text-align: justify;
	flex-grow: 1;
	padding: 1rem;
	margin: 0;
	line-height: 1.3;
	color: $darkGray;
	overflow: hidden;

	.card__more{
		border:none;
		background:none;
		float:right;
		color: var(--color-title);
		font-weight: 800;
		width: 100%;
		text-align: right;
	}
}
.card__action-bar {
	user-select: none;
	bottom: 0;
	padding:  .3rem 1rem;
	border-top: 1px solid #E0E0E0;
	transition: left 200ms cubic-bezier(0.075, 0.82, 0.165, 1);
	display:flex;
	align-items: center;
	justify-content: space-between;
	margin-top:auto;
  .card__action-bar_price{
      font-size: 1.8rem;
      font-weight: 700;
      justify-self: flex-start;
      color: var(--color-title)
  }

	.card__action-bar_addToCart{
		transition: opacity 200ms ease-in-out;
		position: relative;
		text-decoration: none;
		background: transparent;
		border: 0;
		padding: 0;
		user-select: none;
		span{
			position: absolute;
			top: 16px;
			left: 0;
			opacity: 0;
			width: 100%;
			text-align: center;
			animation-name: slideUpFadeOut;
			animation-duration: 1s;
			font-weight: 500;
			user-select: none;
			color: var(--color-primary);
			z-index: 10;
		}
		svg{
			width: 2.5rem;
			height: 2.5rem;
			fill: var(--color-primary);

			&:hover{
				opacity: .8
			}
		}
	}
}

@keyframes slideUpFadeOut {
	0%   {top: 10px; opacity: 0}
	50%  {top: -16px; opacity: 1}
	100% {top: -18px; opacity: 0;}
  }
