
.successIcon {
    animation-duration: 1.8s;
    animation-fill-mode: both;
    animation-name: bounceIn;
  }
  
  
  @keyframes bounceIn {
      0%, 100%, 20%, 40%, 60%, 80% {
          animation-timing-function: cubic-bezier(0.215, .61, .355, 1)
      }
      0% {
          opacity: 0;
          transform: scale3d(.3, .3, .3)
      }
      20% {
          transform: scale3d(1.1, 1.1, 1.1)
      }
  
      40% {
          transform: scale3d(.9, .9, .9)
      }
      60% {
          opacity: 1;
          transform: scale3d(1.03, 1.03, 1.03)
      }
      80% {
          transform: scale3d(.97, .97, .97)
      }
      100% {
          opacity: 1;
          transform: scale3d(1, 1, 1)
      }
  }