/* Customize the label (the checkbox-container) */
.checkbox-container {
    display: block;
    position: relative;
    padding-left: 2rem;
    margin-bottom: 1rem;
    cursor: pointer;
    font-size: 1rem;
    user-select: none;
    &.error{
      color: var(--color-error)
    }
  }
  
  /* Hide the browser's default checkbox */
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.5rem;
    width: 1.5rem;
    background-color: #fbfbfb;
    border:1px solid var(--color-primary)
  }
  
  /* On mouse-over, add a grey background color */
  .checkbox-container:hover input ~ .checkmark {
    background-color:var(--color-primary-text);
  }
  
  /* When the checkbox is checked, add a blue background */
  .checkbox-container input:checked ~ .checkmark {
    background-color: var(--color-primary);
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .checkbox-container .checkmark:after {
    left: 0.3rem;
    top: 0.1rem;
    width: 0.6rem;
    height: 0.8rem;
    border: solid var(--color-primary-text);
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }