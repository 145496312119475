fieldset {
    padding: 0.5rem 2rem;
  }
  
  legend {
    text-transform: uppercase;
    font-size: 0.8em;
    letter-spacing: 0.1rem;
  }

  .input-wrapper{
    .input-error{
      color: var(--color-error);
    }
  }
  
  input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="color"]):not([type="button"]):not([type="reset"]),
  select,
  textarea,
  textarea[type="text"] {
    -webkit-appearance: none;
    background-color: white;
    font-family: inherit;
    padding: 0.8rem 1rem;
    border-radius: 4px;
    border: 1px solid var(--color-lightGrey);
    font-size: 1em;
    transition: all 0.2s ease;
    display: block;
    width: 100%;
  }

  select{
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 5px;
  }
  
  input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="color"]):not([type="button"]):not([type="reset"]):not(:disabled):hover,
  select:hover,
  textarea:hover,
  textarea[type="text"]:hover {
    border-color: var(--color-grey);
  }
  
  input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="color"]):not([type="button"]):not([type="reset"]):focus,
  select:focus,
  textarea:focus,
  textarea[type="text"]:focus {
    outline: none;
    border-color: var(--color-primary);
    box-shadow: 0 0 1px var(--color-primary);
  }
  
  input.error:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="color"]):not([type="button"]):not([type="reset"]),
  textarea.error,
  select.error {
    border-color: var(--color-error);
  }
  
  input.success:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="color"]):not([type="button"]):not([type="reset"]),
  textarea.success {
    border-color: var(--color-success);
  }

  [type="checkbox"],
  [type="radio"] {
    width: 1.6rem;
    height: 1.6rem;
  }

  //Poichè firefox non centra il bollino di selezione
  @-moz-document url-prefix() {
    [type="checkbox"],
    [type="radio"] {
      width: unset;
      height: unset;
    }
  }
  
  /* BUTTONS */
  .button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    height: 3rem;
    padding: 1rem 2.5rem;
    color: var(--color-primary-text);
    background: var(--color-primary);
    border-radius: $borderRadius;
    border: 1px solid transparent;
    line-height: 1rem;
    text-align: center;
    transition: opacity 0.2s ease;
    text-decoration: none;
    transform: scale(1);
    display: inline-block;
    cursor: pointer;

    &.loading{
      padding: .2rem 2.5rem;
    }
    &.bottom{
      margin-top: auto;
      margin-bottom: 1rem;
    }
  }
