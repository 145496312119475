
.swiper-container {
  padding: 1rem 0 1rem 0;
  margin: 0 -1.2rem;
}

.swiper-slide{
  padding: 1rem;
  height: auto;
  .card{
    height: 100%;
  }
}

#productSlider {
    margin-left:-3.8rem;
    .swiper-slide{
      width: unset;
      &:first-child{
        padding-left: 2rem;
      }
    }

    .spinner{
      padding-left: 2.6rem
    }
  }

  .swiper-container.categories{
    
    .swiper-scrollbar-drag{
      background: var(--color-primary);
    }
    .category{
      border-radius: $borderRadius;
      background-color: #EEEEEE;
      width: 12rem;
      padding: 1rem; 
      color: $darkGray; 
      transition: background-color .4s ease-in-out;
      &.category__two-rows
      {
         // width: 14rem;
         padding-bottom: 0;
          .category_title{      
            text-align: right;
          }
      }

      > div{
        display:flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
      }
  
      .category_title{      
        font-weight: 500;
        font-size: 1.3rem;
        user-select: none;
      }
  
      &.swiper-slide-active{
        background-color: var(--color-primary);
        color: var(--color-primary-text);    
        box-shadow: 1px 1px 14px 3px rgba(0, 0, 0, 0.3);       
      }
  
      .category_icon {
        font-size: 4rem;
      }
      .category_total {
        font-size: 3rem;
        user-select: none;
      }
    }
  
  }

  .swiper-slide.menu{
    width: auto;

    &.menu__active{
      color: var(--color-primary);
      border-bottom: 1px solid var(--color-primary);
      button{      
        color: var(--color-primary);
      }
    }
    button{
      background: transparent;
      border: 0;
    }
  }