.errorPageContainer{
    display: flex;
    width: 100%;
    text-align: center;
    flex-direction: column;
    p{
        font-size: 60px;
        margin: 0;
    }
    h1{
        font-size: 60px;
        line-height: 60px;
        margin-bottom: 10px;
    }
    h3{
        font-size: 30px;
        line-height: 30px;
    }
}