@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300&display=swap');

@import '_variables';
*{
  box-sizing: border-box;
  outline:none;
}

:root{
  --color-title: #443F4A;
  --color-primary: #6066D0;
  --color-primary-text: #FFF;
  --color-lightGrey: #d2d6dd;
  --color-grey: #747681;
  --color-darkGrey: $darkGray;
  --color-error: #d43939;
  --color-success: #28bd14;
}

html, body{
  font-size: 14px;
  font-family: 'Rubik', sans-serif;
  padding: 0.6rem;
  margin: 0;
  color: #464646
}

body{
  padding-bottom: $cartClosedHeight;
}

h1, h2, h3, h4{
    color:  var(--color-title);
    margin-top: 0rem;
}
h1{
  margin-top: 0rem;
}

.p-1{
  padding: 1 rem;
}

.copyright{
  color: var(--color-grey);
  opacity: .8;
  a{
    font-weight: 400;
    text-decoration: none;
    color: var(--color-primary);
  }
}

.disabled{
  font-size: 2em;
  color: rgb(107, 107, 107);
  text-align: center;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}


.section_title {
  color: #717073;
  font-size: 1.3rem;
  font-weight: 500;
  margin-bottom: 0;
}

.spinner{
  margin: 0 auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
   > div > div{
    background-color: var(--color-primary);
  }
  span{
    display:block;
    margin-top: 1rem;
  }
}

.noscroll{
  height: 100%;
  overflow: hidden;
 //iOS hack
  position: fixed;
}

.hide{
  display: none !important;
}

.text-right{
  text-align: right;
}

.fullscreen{
  width: 100%;
  flex:1;
  background: white;
  position: absolute;
  z-index: 100;
  left: 0;
  top: 0;
}

.fullscreen-top{
  position: fixed;
  z-index: 10;
  background-color: white;
  height: 100vh;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 1rem;
  overflow: scroll;
}

.fullflex{
  display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
}

.flex-center{
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-center{
  text-align: center;
}

.margin-auto{
  margin: 0 auto;
}

.margin-top-auto{
  margin-top: auto;
}
.margin-bottom-1{
  margin-bottom: 1rem;
}

.autocomplete-ul{
  margin-top:-15px;
  list-style: none !important;
  padding:0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: 1px solid var(--color-lightGrey);
  border-bottom: 1px solid var(--color-lightGrey);
  border-right: 1px solid var(--color-lightGrey);
}

.autocomplete-li{
  padding: 5px;
  cursor: pointer;
  border-top: 1px solid var(--color-lightGrey);
}
.autocomplete-li:hover{
  background-color: var(--color-lightGrey);
}

@import '_form';
@import '_buttons';
@import '_checkbox';
@import '_alert';
@import '_modal';
@import 'slider';
@import 'hamburgerIcon';
@import "_icons.scss"
//@import 'cart';
