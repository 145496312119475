
  .button{
    .spinner {                
        display: inline-block;
        > div > div{
            background-color: var(--color-primary-text);
        }
    }
  }
   
  .button + .button {
    margin-left: 1rem;
  }  
  .button + .button.block {
    margin-left: unset;
    margin-top: 1rem;
  }
  
  .button:hover,
  [type="button"]:hover,
  [type="reset"]:hover,
  [type="submit"]:hover,
  button:hover {
    opacity: 0.8;
  }
  
  .button:active,
  [type="button"]:active,
  [type="reset"]:active,
  [type="submit"]:active,
  button:active {
    transform: scale(0.98);
  }
  
  input:disabled,
  button:disabled,
  input:disabled:hover,
  button:disabled:hover {
    opacity: 0.4;
    cursor: not-allowed;
  }
  
  .button.primary,
  .button.secondary,
  .button.dark,
  .button.error,
  .button.success,
  [type="submit"] {
    color: #fff;
    z-index: 1; /* hightlight from other button's border when grouped */
    background-color: #000;
    background-color: var(--color-primary);
  }
  
  .button.secondary {
    background-color: var(--color-grey);
  }
  
  .button.dark {
    background-color: var(--color-darkGrey);
  }
  
  .button.error {
    background-color: var(--color-error);
  }
  
  .button.success {
    background-color: var(--color-success);
  }
  
  .button.outline {
    background-color: transparent;
    border-color: var(--color-primary);
    color: var(--color-primary);
  }
  
  .button.outline.primary {
    border-color: var(--color-primary);
    color: var(--color-primary);
  }
  
  .button.outline.secondary {
    border-color: var(--color-grey);
    color: var(--color-grey);
  }
  
  .button.outline.dark {
    border-color: var(--color-darkGrey);
    color: var(--color-darkGrey);
  }
  
  .button.clear {
    background-color: transparent;
    border-color: transparent;
    color: var(--color-primary);
  }
  
  .button.block{
    display: block;
    width: 100%;
  }

  
  ::placeholder {
    color: #bdbfc4;
  }


