.alert{
    padding: .5rem;
    text-align: center;
    margin: .5rem;
    border-radius: $borderRadius;
    &.error{
        color: var(--color-error);
        border: 1px solid var(--color-error);

        text-align: left;
        ul {
            padding-left: 2rem;
            margin: .5rem;
        }
    }
    &.success{
        color: var(--color-success);
        border: 1px solid var(--color-success);
    
    }
}